@import "open-color/open-color";

.excalidraw {
  .layer-ui__library-sidebar {
    display: flex;
    flex-direction: column;
  }

  .layer-ui__library {
    display: flex;
    flex-direction: column;

    flex: 1 1 auto;

    .layer-ui__library-header {
      display: flex;
      align-items: center;
      width: 100%;
      margin: 2px 0 15px 0;
      .Spinner {
        margin-right: 1rem;
      }

      button {
        // 2px from the left to account for focus border of left-most button
        margin: 0 2px;
      }
    }
  }

  .layer-ui__sidebar {
    .library-menu-items-container {
      height: 100%;
      width: 100%;
    }
  }

  .library-actions {
    width: 100%;
    display: flex;
    margin-right: auto;
    align-items: center;

    button .library-actions-counter {
      position: absolute;
      right: 2px;
      bottom: 2px;
      border-radius: 50%;
      width: 1em;
      height: 1em;
      padding: 1px;
      font-size: 0.7rem;
      background: #fff;
    }

    &--remove {
      background-color: $oc-red-7;
      &:hover {
        background-color: $oc-red-8;
      }
      &:active {
        background-color: $oc-red-9;
      }
      svg {
        color: $oc-white;
      }
      .library-actions-counter {
        color: $oc-red-7;
      }
    }

    &--export {
      background-color: $oc-lime-5;

      &:hover {
        background-color: $oc-lime-7;
      }

      &:active {
        background-color: $oc-lime-8;
      }
      svg {
        color: $oc-white;
      }
      .library-actions-counter {
        color: $oc-lime-5;
      }
    }

    &--publish {
      background-color: $oc-cyan-6;
      &:hover {
        background-color: $oc-cyan-7;
      }
      &:active {
        background-color: $oc-cyan-9;
      }
      svg {
        color: $oc-white;
      }
      label {
        margin-left: -0.2em;
        margin-right: 1.1em;
        color: $oc-white;
        font-size: 0.86em;
      }
      .library-actions-counter {
        color: $oc-cyan-6;
      }
    }

    &--load {
      background-color: $oc-blue-6;
      &:hover {
        background-color: $oc-blue-7;
      }
      &:active {
        background-color: $oc-blue-9;
      }
      svg {
        color: $oc-white;
      }
    }
  }

  .layer-ui__library-message {
    padding: 2em 4em;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .Spinner {
      margin-bottom: 1em;
    }
    span {
      font-size: 0.8em;
    }
  }

  .publish-library-success {
    .Dialog__content {
      display: flex;
      flex-direction: column;
    }

    &-close.ToolIcon_type_button {
      background-color: $oc-blue-6;
      align-self: flex-end;
      &:hover {
        background-color: $oc-blue-8;
      }
      .ToolIcon__icon {
        width: auto;
        font-size: 1rem;
        color: $oc-white;
        padding: 0 0.5rem;
      }
    }
  }

  .library-menu-browse-button {
    width: 80%;
    min-height: 22px;
    margin: 0 auto;
    margin-top: 1rem;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;

    border-radius: var(--border-radius-lg);
    background-color: var(--color-primary);
    color: $oc-white;
    text-align: center;
    white-space: nowrap;
    text-decoration: none !important;
    &:hover {
      background-color: var(--color-primary-darker);
    }
    &:active {
      background-color: var(--color-primary-darkest);
    }
  }

  .library-menu-browse-button--mobile {
    min-height: 22px;
    margin-left: auto;
    a {
      padding-right: 0;
    }
  }
}
